import React from 'react'

import { usePageData } from '../hooks/usePageData'

import Layout from '../components/Layout'
import About from '../components/About'
import OperatingModel from "../components/OperatingModel"
import Hero from "../components/Hero"
import Contact from "../components/Contact"
import ProjectList from "../components/ProjectList"
import SEO from "../components/Seo"
import Palvelut from '../components/Palvelut'
import { switchNodeLocale } from '../utils/contentful';

type Props = {
  pageContext: {
    language: 'fi' | 'en'
    intl: {

    }
  }
  location: Location
}

const Home = ({ pageContext, location }: Props) => {
  const { language } = pageContext
  const { content, metadata } = usePageData()

  const contentFiltered = switchNodeLocale(content.edges, language)
  const [metadataFiltered] = switchNodeLocale(metadata.edges, language)
  return (
    <Layout location={location} locale={language}>
      {contentFiltered.map(({ node }, i) => (
        <>
          <SEO
            lang={language}
            title={metadataFiltered.node.title}
            description={metadataFiltered.node.description}
          />
          <main className="mt-24 lg:mt-0 max-w-screen-15inchLaptop mx-auto">
            <Hero content={node.hero} />
            <Palvelut services={node.service} />
            <OperatingModel model={node.operatingModel} />
            <ProjectList projects={node.projects} locale={language} />
            <About aboutUs={node.aboutUs} />
            <Contact contactForm={node.contactForm} locale={language} />
          </main>
        </>
      ))}
    </Layout>
  )
}

export default Home
