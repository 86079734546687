import classNames from 'classnames'
import React from 'react'

import { Services } from '../../types'

import styles from './styles.module.css'

type PalvelutProps = {
  services: Services
}

const Palvelut = ({ services }: PalvelutProps): JSX.Element => {
  return (
    <div
      id="palvelut"
      className={classNames('grid mb-24 lg:mb-64', styles.wrapper)}
    >
      <div className="sm:my-auto mx-auto w-9/12 sm:w-auto sm:self-center">
        <h1 className="hidden leading-none	md:block heading mb-10 md:mb-16 text-3xl md:text-5xl lg:text-6xl uppercase">
          {services.title}
        </h1>
        <ul className="hidden md:block text-sm sm:text-base md:text-xl lg:text-2xl leading-normal">
          {services.descriptionList.map(service => (
            <li
              key={service}
              className="list-disc list-inside font-sortsMilRegular"
            >
              {service}
            </li>
          ))}
        </ul>
      </div>
      <img
        alt={services.title}
        src={services.image.file.url}
        className={styles.img}
        loading="lazy"
      />
      <h1
        className="md:hidden heading mt-10 leading-none text-center text-3xl md:text-5xl lg:text-6xl uppercase"
        style={{ width: '100%' }}
      >
        {services.title}
      </h1>
      <ul className={classNames('md:hidden pt-10 text-base', styles.list)}>
        {services.descriptionList.map(service => (
          <li
            key={service}
            className="list-disc list-inside font-sortsMilRegular"
          >
            {service}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Palvelut
