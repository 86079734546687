import React from 'react'
import classNames from 'classnames'

import * as styles from './styles.module.css'
import { AboutUs } from '../../types'
import { handleSplitTitle } from '../../utils/contentful';

type AboutUsProps = {
	aboutUs: AboutUs
}

const About = ({ aboutUs }: AboutUsProps) => {
	 const handleBreakLine = (text: string) => {
     const hasBreak = text.toLowerCase().includes('<br>')
     if (hasBreak) {
       const paragraphs = handleSplitTitle(text)
       return paragraphs.map((key, i) =>
         i !== 0 ? (
           <>
             <br />
             <span>{key}</span>
           </>
         ) : (
           <span>{key}</span>
         ),
       )
     }
     return <span>{text}</span>
   }
	return (
    <div
      id="about"
      className={classNames('mx-auto grid mt-24 lg:mt-64', styles.wrapper)}
    >
      <img
        alt={aboutUs.title}
        src={aboutUs.image.file.url}
        className={styles.img}
        loading="lazy"
      />
      <div className="sm:self-center md:mt-10 sm:w-5/6 lg:w-12/12 mx-auto">
        <h1 className="heading mb-10 md:mb-16 leading-none md:text-5xl lg:text-6xl text-center mx-auto pt-10 sm:pt-0 ">
          {aboutUs.title}
        </h1>
        <p className="px-10 md:px-0 text-center text-sm sm:text-base md:text-xl lg:text-2xl mx-auto">
          {handleBreakLine(aboutUs.description.description)}
        </p>
      </div>
    </div>
  )
}

export default About
