import { graphql, useStaticQuery } from 'gatsby'

import { LandingPageProps } from '../types'

export const usePageData = (): LandingPageProps => {
  const data = useStaticQuery(
    graphql`
      {
        metadata: allContentfulMetadata(filter: { page: { in: "Home" } }) {
          edges {
            node {
              node_locale
              page
              title
              description
            }
          }
        }
        content: allContentfulLandingPage {
          edges {
            node {
              node_locale
              pageName
              hero {
                title
                description {
                  description
                }
                image {
                  title
                  file {
                    url
                  }
                }
                node_locale
              }
              service {
                title
                descriptionList
                image {
                  title
                  file {
                    url
                  }
                  gatsbyImageData(
                    placeholder: BLURRED
                    width: 500
                    formats: [AUTO, WEBP]
                    quality: 100
                  )
                }
                node_locale
              }
              operatingModel {
                title
                content {
                  no
                  title
                  description
                }
                node_locale
              }
              projects {
                title
                housingType
                seoDescription
                customUri
                cardCover {
                  file {
                    url
                  }
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    quality: 100
                  )
                }
                heroImage {
                  title
                  file {
                    url
                  }
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    quality: 100
                  )
                }
                portfolioPictures {
                  title
                  file {
                    url
                  }
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    quality: 100
                  )
                }
                node_locale
              }
              aboutUs {
                description {
                  description
                }
                title
                image {
                  title
                  file {
                    url
                  }
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    quality: 100
                    width: 621
                    height: 797
                  )
                }
                node_locale
              }
              contactForm {
                title
                descriptionList
                node_locale
              }
            }
          }
        }
      }
    `,
  )
  return data
}

export default usePageData
