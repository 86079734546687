import React, { useState } from 'react'
import classNames from 'classnames'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { handleSplitTitle } from '../../utils/contentful'

import * as styles from './styles.module.css'
import { ContactForm } from '../../types';

type ContactFormProps = {
  contactForm: ContactForm
  locale: 'fi' | 'en'
}

const ValidationSchema = Yup.object().shape({
  nimi: Yup.string().required('Pakollinen'),
  sahkoposti: Yup.string().required('Pakollinen').email('Väärän muotoinen sähköpostiosoite'),
  viesti: Yup.string().required('Pakollinen')
})

const Contact = ({ contactForm, locale }: ContactFormProps) => {

  const initialValues = {
    nimi: "",
    sahkoposti: "",
    viesti: ""
  }

  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true)

  const onCloseModal = () => setOpen(false)

   const handleBreakLine = (text: string) => {
     const hasBreak = text.toLowerCase().includes('<br>')
     if (hasBreak) {
       const paragraphs = handleSplitTitle(text)
       return paragraphs.map((key, i) =>
         i !== 0 ? (
           <>
             <br />
             <span>{key}</span>
           </>
         ) : (
           <span>{key}</span>
         ),
       )
     }
     return <span>{text}</span>
   }

  const encode = (data: any) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleSubmit = async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", ...values })
    })
      .then(() => {
        onOpenModal()
        actions.resetForm()
      })
      .catch(() => {
        alert('Error');
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <div
      id="otayhteytta"
      className="mx-auto grid sm:grid-cols-2 my-24 sm:my-24 lg:my-64"
    >
      <Modal
        open={open}
        onClose={onCloseModal}
        styles={{ modal: { background: '#E1DED5' } }}
        center
      >
        <div className="p-3 sm:p-10 md:p-12 ">
          <h1 className="heading md:text-5xl lg:text-6xl text-bold uppercase">
            {locale === 'fi' ? 'kiitos!' : 'Thank you'}
          </h1>
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl">
            {locale === 'fi'
              ? ' Olemme vastaanottaneet viestisi ja otamme sinuun yhteyttä mahdollisimman pian!'
              : 'We have received your message and will contact you as soon as possible!'}
          </p>
        </div>
      </Modal>
      <div className="md:mt-2">
        <h1 className="heading leading-none md:text-5xl lg:text-6xl text-center">
          {contactForm.title}
        </h1>
        <div className="text-center m-10 my-10 md:my-16 lg:px-24 text-sm sm:text-lg lg:text-xl">
          {contactForm.descriptionList.map((content, index) => (
            <p className="pb-5 lg:pb-10" key={index}>
              {handleBreakLine(content)}
            </p>
          ))}
        </div>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          <Form
            className="text-base sm:text-md md:text-xl lg:text-2xl mx-12 md:mx-10 lg:mx-5 "
            name="contact-form"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div className="mx-2 sm:mx-5 lg:mx-32">
              <label
                className={classNames('-ml-2 sm:ml-0', styles.headingForm)}
              >
                {locale === 'fi' ? 'nimi' : 'name'}
              </label>
              <Field
                className={styles.inputForm}
                type="text"
                name="nimi"
              ></Field>
              <ErrorMessage name="nimi">
                {msg => <p className="text-black text-sm lg:text-lg">{msg}</p>}
              </ErrorMessage>
            </div>
            <div className="mx-2 sm:mx-5 lg:mx-32 my-5">
              <label
                className={classNames('-ml-2 sm:ml-0', styles.headingForm)}
              >
                {locale === 'fi' ? 'sähköposti' : 'Email'}
              </label>
              <Field
                className={styles.inputForm}
                type="email"
                name="sahkoposti"
              />
              <ErrorMessage name="sahkoposti">
                {msg => <p className="text-black text-sm lg:text-lg">{msg}</p>}
              </ErrorMessage>
            </div>
            <div className="mx-2 sm:mx-5 lg:mx-32 my-5">
              <label
                className={classNames('-ml-2 sm:ml-0', styles.headingForm)}
              >
                {locale === 'fi' ? 'viesti' : 'message'}
              </label>
              <Field
                className={styles.inputForm}
                type="text"
                name="viesti"
                as="textarea"
                rows="3"
              />
              <ErrorMessage name="viesti">
                {msg => <p className="text-black text-sm lg:text-lg">{msg}</p>}
              </ErrorMessage>
            </div>
            <div className="mx-2 sm:mx-5 lg:mx-32 my-5 text-center">
              <button
                className={classNames(
                  'text-md sm:text-lg md:text-xl lg:text-2xl',
                  styles.button,
                )}
                type="submit"
              >
                {locale === 'fi' ? 'lähetä' : 'send'}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default Contact
