import classNames from 'classnames'
import React from 'react'

import { OperatingModelType } from '../../types'

import styles from './styles.module.css'

type OperatingModelProps = {
  model: OperatingModelType
}

const OperatingModel = ({ model }: OperatingModelProps) => {

  return (
    <div className={classNames('w-11/12 mx-auto mb-24 lg:mb-64', styles.wrapper)}>
      <h1 className="mb-10 md:mb-16 leading-none heading md:text-5xl lg:text-6xl text-center">
        {model.title}
      </h1>
      <ul className="text-black lg:flex lg:flex-column lg:relative lg:w-11/12 lg:mx-auto">
        {model.content.map(step => (
          <li
            key={step.no}
            className={classNames(
              'my-8 lg:my-0 text-center lg:text-left lg:flex-1',
              styles.step,
            )}
            {...{ 'data-before': `${step.no}` }}
          >
            <div className="px-4">
              <h2 className="text-4xl lg:hidden">{step.no}</h2>
              <h2 className="text-xl xl:text-2xl my-2 lg:mt-0 lg:pl-8">
                {step.title}
              </h2>
              <p className="mx-auto lg:mt-10 sm:w-9/12 md:w-8/12 lg:w-full lg:px-6 lg:pb-8">
                {step.description}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default OperatingModel