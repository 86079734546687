import React from 'react'
import { Link } from 'gatsby'

import { Project } from '../../types'

import styles from './styles.module.css'
import { handlePageNavigation } from '../../utils/contentful'

type ProjectListProps = {
  projects: Project[]
  locale: 'fi' | 'en'
}

const ProjectList = ({ projects, locale }: ProjectListProps) => {
  return (
    <div
      id="projektit"
      className="flex flex-col md:flex-row flex-wrap justify-end md:justify-center mt-10 mr-0 lg:w-12/12"
    >
      {projects.slice(0, 1).map(project => (
        <Link
          key={project.title}
          to={handlePageNavigation({
            fiPath: 'projektit',
            enPath: 'projects',
            locale: locale,
            uri: '',
            returnPath: true,
          })}
          className="justify-self-end"
        >
          <div className="flex flex-col mb-4 items-end">
            <div className="flex flex-col sm:flex-row items-end">
              <img
                alt={project.title}
                src={project.cardCover.file.url}
                className={styles.img}
              />
            </div>
            <h1 className="heading text-3xl md:text-5xl lg:text-6xl mt-2 md:mb-0 lg:mt-16 my-6 self-center">
              {locale === 'fi' ? 'projektit' : 'Projects'}
            </h1>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default ProjectList
