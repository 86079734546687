import React from 'react'
import classNames from 'classnames'

import { handleSplitTitle } from '../../utils/contentful'
import { HeroType } from '../../types'

import styles from './styles.module.css'

type HeroProps = {
  content: HeroType
}

const Hero = ({ content }: HeroProps) => {
  const handleBreakLine = (text: string) => {
    const hasBreak = text.toLowerCase().includes('<br>')
    if (hasBreak) {
      const paragraphs = handleSplitTitle(text)
      return paragraphs.map((key, i) =>
        i !== 0 ? (
          <>
            <br />
            <span>{key}</span>
          </>
        ) : (
          <span>{key}</span>
        ),
      )
    }
    return <span>{text}</span>
  }
  return (
    <>
      {/*----------------MOBILE---------------*/}
      <div className="relative mb-10 md:mb-16 lg:hidden">
        <h1
          className={classNames(
            'font-medium uppercase text-center leading-none',
            styles.m_header_first,
          )}
        >
          STUDIO
        </h1>
        <div
          className={styles.m_hero_backdrop}
          style={{
            backgroundImage: `url(${content.image.file.url})`,
          }}
        />
        <h1
          className={classNames(
            'font-medium uppercase text-center leading-none',
            styles.m_header_second,
          )}
        >
          MIDOS
        </h1>
        <p className="text-center text-lg sm:text-2xl lg:text-3xl leading-normal pt-6 pb-10 my-24 xl:my-32">
          {handleBreakLine(content.description.description)}
        </p>
      </div>

      {/*----------------------------------------*/}
      {/*-------------------DESKTOP---------------*/}
      {/*----------------------------------------*/}
      <div className="hidden lg:block relative mb-10 md:mb-16 lg:mb-32 15inchLaptop:mb-64">
        <h1
          className={classNames(
            'font-medium uppercase text-center leading-none',
            styles.header,
          )}
        >
          {handleBreakLine(content.title)}
        </h1>
        <div
          className={styles.hero_backdrop}
          style={{
            backgroundImage: `url(${content.image.file.url})`,
          }}
        />
        <p className="text-center text-lg sm:text-2xl lg:text-3xl leading-normal px-10 mt-10 lg:mt-24 xl:mt-32">
          {handleBreakLine(content.description.description)}
        </p>
      </div>
    </>
  )
}

export default Hero
